import React, { useState, useEffect } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

function FileProcessor() {
  const [files, setFiles] = useState([]);
  const [deviceId, setDeviceId] = useState('');
  const [headerTimeDelta, setHeaderTimeDelta] = useState(0);
  const [timeString, setTimeString] = useState(''); // Now used for days to add (as a string)
  const [selectedChoice, setSelectedChoice] = useState('');
  const [prefix, setPrefix] = useState('');
  const [number, setNumber] = useState('');
  const [modifiedFiles, setModifiedFiles] = useState([]);
  const [failedFiles, setFailedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [globalbatches, setBatches] = useState([]);
  
  const BATCH_SIZE = 85; // Define the batch size

  // If failedFiles change, trigger retry
  useEffect(() => {
    if (failedFiles.length > 0) {
      handleRetryPrompt();
    }
  }, [failedFiles]);

  const handleFileChange = (event) => {
    setFiles(Array.from(event.target.files));
  };

  // Now, this input will represent the number of days to add
  const handleTimeStringChange = (event) => {
    setTimeString(event.target.value);
  };

  const handleChoiceChange = (event) => {
    setSelectedChoice(event.target.value);
    setPrefix('');
    setNumber('');
  };

  const handlePrefixChange = (event) => {
    setPrefix(event.target.value);
    setNumber('');
  };

  const handleNumberChange = (event) => {
    setNumber(event.target.value);
  };

  // Read file data and parse the header timestamp from the file’s header.
  const readData = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const headerPosition = [];
        for (let i = 0; i < data.length; i++) {
          if (data[i] === 252 || data[i] === 253) {
            headerPosition.push(i);
          }
        }
        // Extract header bytes (assumes header starts after first marker)
        const header = data.slice(headerPosition[0] + 1, headerPosition[1]);
        const headerStr = String.fromCharCode(...header);
        // The header timestamp is assumed to be the first 10 characters (epoch seconds)
        const headerTstamp = parseInt(headerStr.substring(0, 10), 10);
        resolve({ rawData: data, headerPosition, headerTstamp });
      };
      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(file);
    });
  };

  // Update the file data with the new device id and header timestamp.
  const updateDeviceIdHtime = (rawData, headerPosition, deviceId, headerTstamp) => {
    const newDevice = Array.from(deviceId.toString()).map((char) => char.charCodeAt(0));
    const newHeader = Array.from(headerTstamp.toString()).map((char) => char.charCodeAt(0));

    for (let i = 0; i < newDevice.length; i++) {
      rawData[headerPosition[0] + 11 + i] = newDevice[i];
    }

    for (let i = 0; i < newHeader.length; i++) {
      rawData[headerPosition[0] + 1 + i] = newHeader[i];
    }

    return rawData;
  };

  // Send one batch of files to the backend.
  const sendBatchToBackend = async (batch, batchIndex, isRetry = false) => {
    setIsUploading(true);
    setUploadSuccess(false);

    const zip = new JSZip();
    const modifiedFilesBuffer = [];

    // For each file in the batch, read and update the header timestamp.
    for (const file of batch) {
      const { rawData, headerPosition, headerTstamp } = await readData(file);
      if (!isRetry) {
        // Interpret the input as the number of days to add.
        const daysToAdd = parseInt(timeString, 10) || 0;
        // Compute the new header timestamp by adding the specified days (in seconds)
        const newHeaderTstamp = headerTstamp + (daysToAdd * 86400);
        const updatedData = updateDeviceIdHtime(rawData, headerPosition, deviceId, newHeaderTstamp);
        zip.file(file.name, updatedData);
        modifiedFilesBuffer.push({ name: file.name, rawData: updatedData });
      } else {
        // In retry, we assume rawData has been modified already.
        zip.file(file.name, rawData);
      }
    }
    
    // Save the modified files buffer (if needed later)
    setModifiedFiles(modifiedFilesBuffer);

    const zipBlob = await zip.generateAsync({ type: 'blob' });
    saveAs(zipBlob, `updatedfiles_batch_${batchIndex + 1}.zip`);
    console.log(`Sending batch ${batchIndex + 1}, size: ${(zipBlob.size / (1024 * 1024)).toFixed(2)} MB`);

    try {
      const formData = new FormData();
      formData.append('file', zipBlob, `updatedfiles_batch_${batchIndex + 1}.zip`);
      formData.append('prefix', prefix);
      formData.append('number', number);
      formData.append('choice', selectedChoice);

      const response = await fetch('https://analytics.dozeeapp.com/file', {
        method: 'POST',
        body: formData,
      });

      if (response.status === 200) {
        console.log(`Batch ${batchIndex + 1} uploaded successfully!`);
        setUploadSuccess(true);
        if (isRetry) {
          setFailedFiles([]);
        }
      } else {
        console.error('Error uploading batch:', response.statusText);
        const failedResponse = await response.json();
        console.log(failedResponse);
        if (failedResponse.failed_files.length !== batch.length) {
          setFailedFiles(failedResponse.failed_files || []);
        }
      }
    } catch (error) {
      console.error('Exception during batch upload:', error);
      handleRetryPrompt();
    } finally {
      setIsUploading(false);
    }
  };

  const handleRetryPrompt = (batchIndex) => {
    retryFailedUploads(batchIndex);
  };

  const retryFailedUploads = (batchIndex) => {
    if (failedFiles.length > 0) {
      const batch = [];
      console.log("Modified files:", modifiedFiles);
      // Match each failed file with its modified version stored in globalbatches (if available)
      for (const fileName of failedFiles) {
        for (const fileObj of globalbatches) {
          if (fileName === fileObj.name) {
            batch.push(fileObj);
          }
        }
      }
      sendBatchToBackend(batch, batchIndex, true);
    }
  };

  // Start processing files by splitting into batches and sending each batch.
  const startReading = async () => {
    console.log("Total files =", files.length);

    // For demonstration, the deviceId is hardcoded.
    const deviceID = "79f9494a-3bba-4271-98b0-035ec0799abf";
    if (!deviceID) {
      console.error("Device ID could not be retrieved.");
      return;
    }
    setDeviceId(deviceID);
    console.log("Device ID:", deviceID);

    // Create batches of files.
    const batches = [];
    for (let i = 0; i < files.length; i += BATCH_SIZE) {
      const batch = files.slice(i, i + BATCH_SIZE);
      batches.push(batch);
    }

    // Process and send each batch sequentially.
    for (let batchIndex = 0; batchIndex < batches.length; batchIndex++) {
      const batch = batches[batchIndex];
      // Save the current batch globally in case you need to retry.
      setBatches(batch);
      console.log("Processing batch:", batch);
      await sendBatchToBackend(batch, batchIndex);
    }
  };

  const numberOptions = {
    'SIT-India': {
      'TST': ['3501', '3504', '3505', '3502', '3503', '3506', '3507', '3508', '3509', '3510', '3511', '3512', '3513', '3514', '3515', '3516', '3517', '3518', '3519', '3520', '3521', '3522', '3523', '3526', '3527', '3528', '3529'],
    },
    'SIT-US': {
      'TST': ['3517', '3518', '3501', '3502', '3503', '3504', '3505', '3506', '3507', '3508', '3509', '3510', '3511', '3512', '3513', '3514', '3515', '3516', '3519', '3520', '3521', '3522', '3523', '3526', '3527', '3528'],
    },
    'Prod-India': {
      'TST': ['3510', '3511', '3513', '3521', '3517', '3516', '3512', '3508', '3528', '3501', '3502', '3503', '3504', '3505', '3506', '3507', '3508', '3509', '3512', '3514', '3515', '3516', '3517', '3518', '3519', '3520', '3522', '3523', '3526', '3527', '3528', '3529'],
    },
    'Prod-US': {
      'TST': ['3517', '3518', '3501', '3502', '3503', '3504', '3505', '3506', '3507', '3508', '3509', '3510', '3511', '3512', '3513', '3514', '3515', '3516', '3519', '3520', '3521', '3522', '3523', '3526', '3527', '3528'],
    },
  };

  // Styling
  const styles = {
    container: {
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
      backgroundColor: '#f7f7f7',
      borderRadius: '8px',
      boxShadow: '0 0 10px rgba(0,0,0,0.1)',
      maxWidth: '600px',
      margin: 'auto'
    },
    header: {
      fontSize: '24px',
      marginBottom: '20px',
      textAlign: 'center',
    },
    input: {
      marginBottom: '10px',
      width: '100%',
      padding: '10px',
      fontSize: '16px',
      borderRadius: '4px',
      border: '1px solid #ccc'
    },
    button: {
      marginTop: '10px',
      padding: '10px 20px',
      fontSize: '16px',
      backgroundColor: '#4CAF50',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
    },
    select: {
      marginBottom: '10px',
      padding: '10px',
      width: '100%',
      fontSize: '16px',
      borderRadius: '4px',
      border: '1px solid #ccc'
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>File Upload Processor</h1>

      <label htmlFor="device-choice">Select Server:</label>
      <select value={selectedChoice} onChange={handleChoiceChange} style={styles.select}>
        <option value="">Select Server</option>
        <option value="SIT-India">SIT-India</option>
        <option value="SIT-US">SIT-US</option>
        <option value="Prod-India">Prod-India</option>
        <option value="Prod-US">Prod-US</option>
      </select>

      <label htmlFor="prefix">Prefix:</label>
      <select value={prefix} onChange={handlePrefixChange} style={styles.select}>
        <option value="">Select Prefix</option>
        <option value="TST">TST</option>
      </select>

      <label htmlFor="number">Device ID:</label>
      <select value={number} onChange={handleNumberChange} style={styles.select}>
        <option value="">Select Device ID</option>
        {numberOptions[selectedChoice] && numberOptions[selectedChoice][prefix] && numberOptions[selectedChoice][prefix].map((num) => (
          <option key={num} value={num}>
            {num}
          </option>
        ))}
      </select>

      {/* The following input is now interpreted as the number of days to add */}
      <label htmlFor="time-string">Days to Add:</label>
      <input 
        type="text" 
        value={timeString} 
        onChange={handleTimeStringChange} 
        placeholder="Enter number of days to add" 
        style={styles.input} 
      />

      <label htmlFor="file-upload">Upload Files:</label>
      <input type="file" multiple onChange={handleFileChange} style={styles.input} />

      <button onClick={startReading} style={styles.button} disabled={isUploading}>
        Start Processing
      </button>

      {isUploading && <p>Uploading files, please wait...</p>}
      {uploadSuccess && <p>Upload successful!</p>}
    </div>
  );
}

export default FileProcessor;
